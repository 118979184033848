/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useState, useEffect } from 'react';

import H1 from 'elements/H1'
import Text from 'elements/Text'
import Button from 'elements/Button'

import './index.scss'

const FirstBlock = ({ title, description, subBlocks, onClick }) => {
  const [src, setSrc] = useState(2)
  console.log(subBlocks)
  return (
    <div className="home-first-block">
        <div className="image-part"> 
            <img src={subBlocks[src]?.image?.file?.url} alt="" className="main-img"/>
            <div className="image-slide-spans">
              {/**
              <span
                type="button"
                onClick={() => setSrc(0)}
                sx={{backgroundColor: 'primary', marginBottom: '25px', opacity: src === 0 ? '1' : '0.3' }}
                className={src === 0 ? 'image-slide-span image-slide-span-active' : 'image-slide-span'}
              ></span>
              <span
                onClick={() => setSrc(1)}
                sx={{backgroundColor: 'primary', marginBottom: '25px', opacity: src === 1 ? '1' : '0.3'}}
                className={src === 1 ? 'image-slide-span image-slide-span-active' : 'image-slide-span'}
              ></span>
              <span
                onClick={() => setSrc(2)} 
                sx={{backgroundColor: 'primary',  opacity: src === 2 ? '1' : '0.3'}}
                className={src === 2 ? 'image-slide-span image-slide-span-active' : 'image-slide-span'}
              ></span>
              */}
            </div>
        </div>
        <div className="right-part">
          <H1 family="Pangram" color="primary" bold className="first-title">{title}</H1>
          <Text size="medium-minus" color="#333333" family="Univers-LT-Std-45-Light" className="first-description">{description?.description}</Text>
          <Button size="medium" className="first-btn">
            <div onClick={() => onClick && onClick()}>
              Notify Me
            </div>
          </Button>
        </div>
    </div>
  )
}

export default FirstBlock