import { useStaticQuery, graphql } from 'gatsby'
import React, { useState } from 'react'

import Layout from 'components/Layout'
// import SEO from 'components/seo'
import FirstBlock from 'routes/Home/FirstBlock'
import SecondBlock from 'routes/Home/SecondBlock'
import ThirdBlock from 'routes/Home/ThirdBlock'
import FourthBlock from 'routes/Home/FourthBlock'
import FifthBlock from 'routes/Home/FifthBlock'
import HowDoesItWorkThirdBlock from 'routes/how-does-it-work/ThirdBlock'
import WhatTheyAreSayingBlock from 'components/WhatTheyAreSayingBlock'

import './index.scss'

const HomePage = () => {
  const data = useStaticQuery(graphql`
    query HomePageQuery {
      contentfulPage(title: {eq: "Home"}) {
        blocks {
          title
          subBlocks {
            ... on ContentfulPageBlock {
              title
              description {
                description
              }
              image {
                file {
                  url
                }
              }
              link {
                label
                path
              }
              button {
                label
                path
              }
              subBlocks {
                ... on ContentfulPageBlock {
                  title
                  description {
                    description
                  }
                  image {
                    file {
                      url
                    }
                  }
                }
              }
            }
          }
          description {
            description
          }
          link {
            label
            path
          }
          image {
            file {
              url
            }
          }
          button {
            label
            path
          }
        }
      }
    }
  `)
  const blocks = data?.contentfulPage?.blocks || []

  const [clicked, setClicked] = useState(false);
  return (
    <Layout isHome={true} showOrder={clicked} closeOrder={() => setClicked(false)}>
      <FirstBlock {...blocks[0]} onClick={() => setClicked(true)} />
      <HowDoesItWorkThirdBlock {...blocks[1]} />
      <ThirdBlock {...blocks[2]} />
      <FifthBlock {...blocks[4]} />
      {/* <FourthBlock {...blocks[3]} /> */}
    </Layout>
  )
}

export default HomePage
